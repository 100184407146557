import React, { useState, useEffect } from "react";
import Header from "../../Header/Header";
import BoloMini from "../../assets/BoloMini.svg";

const DeleteAccount = () => {
  const [formValues, setFormValues] = useState({
    Username: "",
    action: "",
  });
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [Action, setAction] = useState("");

  useEffect(() => {
    if (formValues.action !== "") {
      setAction(formValues.action);
    }
  }, [formValues.action]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    setError("");
  };

  const handleRadioChange = (event) => {
    setFormValues({ ...formValues, action: event.target.value });
    setError("");
  };

  const handleSubmit = () => {
    const onlyNumbersRegex = /^\d+$/;

    if (!formValues.Username.trim()) {
      setError("Please enter your handle/username/phone number.");
      return;
    }

    if (onlyNumbersRegex.test(formValues.Username)) {
      if (formValues.Username.length !== 10) {
        setError("Phone number must be exactly 10 digits long.");
        return;
      }
    }

    if (!formValues.action) {
      setError("Please select an action.");
      return;
    }

    setError("");

    console.log("Form Submitted:", formValues);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const graphql = JSON.stringify({
      query: `mutation Deleteaccount {
        deleteaccount(input: "${formValues.Username}") {
          message
          userId
        }
      }`,
      variables: {},
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
    };

    fetch("https://api.boloindia.co/user/graphql", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Server Error");
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        if (result?.data?.deleteaccount) {
          setShowPopup(true);
          setFormValues({ Username: "", action: "" });
        } else {
          throw new Error("User Error");
        }
      })
      .catch((error) => {
        console.error(error);
        setError("User Error: Unable to process your request. User Not Found!");
      });
  };

  return (
    <>
      {showPopup && (
        <div
          className="Overlay"
          onClick={() => {
            setShowPopup(false);
          }}
        >
          <div
            className="Popup_Box"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <img src={BoloMini} alt="Bolo India" />
            <p style={{ marginTop: "10px", textAlign: "center" }}>
              {error ? error : `Account ${Action} request sent successfully!`}
            </p>
          </div>
        </div>
      )}
      <Header />
      <div className="Margin_Box">
        <h1>Your Bolo India Handle</h1>
        <div className="Bolo_Subscribe">
          <div className="Subscribe_Button">
            <input
              id="emailbox"
              type="text"
              name="Username"
              value={formValues.Username}
              onChange={handleChange}
              placeholder="Enter your Bolo India Handle/Username/Phone number"
              required
            />
          </div>
        </div>
        <h1>Your Action</h1>
        <div className="Radio_Input">
          <input
            type="radio"
            name="delete"
            value="suspend"
            checked={formValues.action === "suspend"}
            onChange={handleRadioChange}
          />
          <p>Suspend Account</p>
        </div>
        <div className="Radio_Input">
          <input
            type="radio"
            name="delete"
            value="deactivate and archive"
            checked={formValues.action === "deactivate and archive"}
            onChange={handleRadioChange}
          />
          <p>Deactivate and Archive Account</p>
        </div>
        <div className="Radio_Input">
          <input
            type="radio"
            name="delete"
            value="permanently delete"
            checked={formValues.action === "permanently delete"}
            onChange={handleRadioChange}
          />
          <p>Permanently Delete Account</p>
        </div>
        {error && (
          <h6
            className="delete_error"
            style={{ color: "red", marginTop: "10px" }}
          >
            {error}
          </h6>
        )}
        <div className="Submit_Delete">
          <button className="Submit_Delete" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
